<template>
  <div>
    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="request-modal"
      size="xl"
      scrollable
      centered>
      <!-- Modal content-->
      <div class="row">
        <div class="col mb-5">
          <h4 class="mb-4">{{ $t('cam-requests.book-cam') }}</h4>

          <div class="font-weight-bold text-left">
            {{ $t('cam-requests.instruction') }}
          </div>

          <div v-if="!loading" class="request-calendar-container">
            <full-calendar id="myugcalendar" class="calendar-modal" ref="calendar" :options="calendarOptions" />
          </div>
          <div v-else>
            <sk-cards :count="1" :height="0.2" width-class="w-100"></sk-cards>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
          <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('cam-requests.close') }}</button>
        </div>
      </div>
      <confirm-request-modal @add-request="handleAddRequest" :prop-date-from="selectFrom" :prop-date-to="selectTo"></confirm-request-modal>
    </b-modal>
  </div>
</template>

<script>
  import SkCards from '../skeletons/sk-cards';
  import ConfirmRequestModal from './confirm-request-modal';
  import CamRequestResource from '../../api/camRequestResource';
  import moment from 'moment';
  import FullCalendar from '@fullcalendar/vue';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import timeGridPlugin from '@fullcalendar/timegrid';

  const camRequestResource = new CamRequestResource();

  export default {
    name: 'request-modal',
    components: { SkCards, FullCalendar, ConfirmRequestModal },

    data() {
      return {
        loading: true,
        camRequests: [],
        rangeFrom: '',
        rangeTo: '',
        selectFrom: '',
        selectTo: '',
        calendarOptions: this.getCalendarOptions(),
      }
    },

    methods: {
      handleAddRequest() {
        this.loadRequests();
      },

      hideModal() {
        this.$bvModal.hide('request-modal');
        this.camRequests = [];
      },

      onShown() {
        this.rangeFrom = moment().format('YYYY-MM-DD');
        this.rangeTo = moment().add(1, 'week').format('YYYY-MM-DD');

        this.loadRequests();
      },

      loadRequests(startDate, endDate) {
        if(!startDate) startDate = this.rangeFrom;
        if(!endDate) endDate = this.rangeTo;

        this.loading = true;
        camRequestResource.requests(startDate, endDate).then(response => {
          this.camRequests = response.data.data;
          this.calendarOptions = this.getCalendarOptions();
        })
        .finally(() => {
          this.loading = false;
        });
      },

      getCalendarOptions() {
        return {
          plugins: [ timeGridPlugin, dayGridPlugin, interactionPlugin ],
          initialView: 'timeGridWeek',
          slotMinTime: '10:00:00',
          slotMaxTime: '19:00:00',
          contentHeight: 'auto',
          slotDuration: '00:30:00',
          allDaySlot: false,
          validRange: {
            start: moment().format('YYYY-MM-DD HH:mm:ss')
          },
          events: this.camRequests,
          selectable: true,
          visibleRange: () => {
            return { start: this.rangeFrom, end: this.rangeTo };
          },
          datesSet: (event) => {
            this.rangeFrom = moment(event.start).format('YYYY-MM-DD');
            this.rangeTo = moment(event.end).format('YYYY-MM-DD');

            camRequestResource.requests(this.rangeFrom, this.rangeTo).then(response => {
              this.camRequests = response.data.data;
            })
          },
          select: (event) => {
            this.selectFrom = moment(event.start);
            this.selectTo = moment(event.end);

            this.$bvModal.show('confirm-request-modal');
          }
        }
      }
    }
  };
</script>
<style scoped>
/* @import '~@fullcalendar/timegrid/main.min.css'; */

.request-calendar-container {
  height: 500px;
}
</style>
